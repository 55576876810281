import { type ReactNode, useMemo } from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import BodyText from "~/components/shared/BodyText";
import DateComponent from "~/components/shared/Date";

type CourseFieldProps = {
	article: typo3.Lehrangebot;
	field: keyof typo3.Lehrangebot & MessageDescriptor["id"];
	children?: ReactNode;
	formatAs?: "text" | "date";
};

function CourseField({
	article,
	field,
	children,
	formatAs = "text",
}: CourseFieldProps) {
	const raw = field in article ? article[field] : null;
	const value = useMemo(() => {
		if (raw === "0") {
			return null;
		}
		if (formatAs === "date" && raw) {
			return new Date(raw);
		}
		return raw;
	}, [raw, formatAs]);

	if (!value) {
		return null;
	}

	let content: ReactNode;
	if (children) {
		content = children;
	} else if (value instanceof Date) {
		content = <DateComponent date={value} year />;
	} else {
		content = (
			<BodyText
				className="whitespace-pre-line"
				dangerouslySetInnerHTML={{ __html: value }}
			/>
		);
	}

	return (
		<div className="essential-item">
			<div className="content-head mode-label element">
				<h4 className="content-title">
					<FormattedMessage id={field} />
				</h4>
			</div>
			<div className="essential-item-content">{content}</div>
		</div>
	);
}

export default function CourseEssentials({
	article,
}: {
	article: typo3.Lehrangebot;
}) {
	return (
		<>
			<div className="essential-item">
				<div className="content-head mode-label element">
					<h4 className="content-title">
						<FormattedMessage id="courseSection" />
					</h4>
				</div>
				<div className="essential-item-content">
					{article.sectionCategories.map((category) => (
						<p key={category.uid}>{category.title}</p>
					))}
				</div>
			</div>
			<CourseField article={article} field="courseFinalDegree" />
			<CourseField article={article} field="courseSemester">
				{article.courseSemesterTyp && (
					<FormattedMessage
						id={`courseSemesterTyp.${article.courseSemesterTyp}`}
					/>
				)}{" "}
				{article.courseSemesterYear}
			</CourseField>
			<CourseField article={article} field="courseWochentypString" />
			<CourseField article={article} field="courseBezeichnung" />
			<CourseField article={article} field="courseVeranstaltungsart" />
			<CourseField article={article} field="courseBenotet" />
			<CourseField article={article} field="courseEcts" />
			{/* TODO
				<CourseField article={article} field="courseAbschlussJahr">
					{courseAbschlussJahr.map()...}
				</CourseField>
			*/}
			<CourseField article={article} field="coursePflichtkennzeichen" />
			<CourseField article={article} field="courseRequirements" />
			<CourseField article={article} field="courseErgaenzungsangebote" />
			<CourseField article={article} field="courseStudienjahr" />
			<CourseField
				article={article}
				field="courseEinschreibefrist"
				formatAs="date"
			/>
			<CourseField
				article={article}
				field="courseRegistrationStart"
				formatAs="date"
			/>
			<CourseField
				article={article}
				field="courseRegistrationEnd"
				formatAs="date"
			/>
			<CourseField
				article={article}
				field="courseBegin"
				formatAs="date"
			/>
			<CourseField article={article} field="courseZeitenUndTermine" />
			<CourseField article={article} field="courseBesprechungszeit" />
			<CourseField article={article} field="courseGuests" />
			<CourseField article={article} field="coursePlace" />
			<CourseField article={article} field="courseKontakt" />
			<CourseField article={article} field="courseModulAngebotTitle">
				{article.courseModulAngebotDescription ||
					article.courseModulAngebotTitle}
			</CourseField>
		</>
	);
}
