import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { cn } from "~/utils/cn";
import type * as typo3 from "~/modules/typo3/schema";
import { isImage, isVideo, isPodcast } from "~/modules/typo3/media";
import { getTypo3BackendUrl } from "~/modules/typo3/backend";
import ScrollContent from "~/components/shared/ScrollContent";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import BodyText from "~/components/shared/BodyText";
import Slideshow from "~/components/Slideshow";
import Calendar from "~/components/shared/calendar/Calendar";
import Video from "~/components/shared/Video";
import Podcast from "~/components/shared/Podcast";
import AppLayout from "~/components/shared/AppLayout";
import EditInCMSButton from "~/components/EditInCMSButton";
import ContentElementsGroup from "~/components/content-elements/ContentElementsGroup";
import MediaEssentials from "~/components/layouts/article/MediaEssentials";

import ArticleDownloads from "./article/ArticleDownloads";
import ArticleEssentialsTitle from "./article/ArticleEssentialsTitle";
import RelatedProjects from "./article/RelatedProjects";
import CourseEssentials from "./article/CourseEssentials";
import RelatedPersonsEssentials from "./article/RelatedPersonsEssentials";
import StudentsEssentials from "./article/StudentsEssentials";
import { useGlobal } from "~/context/global";

type ArticleLayoutProps = {
	page: typo3.Response;
	article: typo3.Article;
};

export default function ArticleLayout({ page, article }: ArticleLayoutProps) {
	const hasTitle = article.title.trim() !== "";

	const videos = article.relatedMedia.filter(isVideo);
	const images = article.relatedMedia.filter(isImage);
	const podcasts = article.relatedMedia.filter(isPodcast);
	const imagesEssentials = article.contextRelatedMedia.filter(isImage);

	const editLink = useMemo(() => {
		const url = getTypo3BackendUrl("/typo3/record/edit");
		url.searchParams.append(
			`edit[tx_nnbuhaarticlesandpersons_domain_model_article][${article.uid}]`,
			"edit",
		);
		return url.toString();
	}, [article.uid]);
	const { isMobile } = useGlobal();
	return (
		<AppLayout layout="page">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					<div
						className={
							"main-content" +
							(isMobile ? " jump-to-nav mobile" : "")
						}
					>
						{article.teasertext.trim() && (
							<div className="module text">
								<div className="text-container">
									<BodyText
										dangerouslySetInnerHTML={{
											__html: `<p>${article.teasertext.replaceAll("\n", "<br />")}</p>`,
										}}
									/>
								</div>
							</div>
						)}

						{videos.map((video, i) => (
							<Video video={video} key={`video-${i}`} />
						))}

						{podcasts.map((podcast, i) => (
							<Podcast podcast={podcast} key={`podcast-${i}`} />
						))}

						{images.length > 0 && (
							<div className="works-showcase module full hero">
								<div className="inner">
									<div className="content">
										<Slideshow
											medias={images}
											size="full"
											mode="work-showcase"
										/>
									</div>
								</div>
							</div>
						)}

						<div className="module text">
							<div className="text-container">
								<BodyText
									dangerouslySetInnerHTML={{
										__html: article.content,
									}}
								/>
							</div>
						</div>

						<ContentElementsGroup
							elements={article.contentElements ?? []}
							size="full"
						/>
					</div>
				</div>

				<div className="column col-aside right">
					{/* TODO: Should the calendar be based on the article section category? */}
					<Calendar sectionDescription={page.meta.title} />
				</div>

				<div className="column col-aside left">
					<div className="essentials module">
						<ScrollContent className="essentials-inner">
							{hasTitle && (
								<header className="page-title essential-item essentials-header sticky top-0">
									<ArticleEssentialsTitle article={article} />
								</header>
							)}

							<div
								className={cn("essentials-content", {
									"no-title": !hasTitle,
								})}
							>
								{article.context && (
									<div className="essential-item module">
										<div className="essential-item-content">
											<BodyText
												dangerouslySetInnerHTML={{
													__html: article.context,
												}}
											/>
										</div>
									</div>
								)}

								{article.articleType === "termin" &&
									article.eventPlace && (
										<div className="essential-item">
											<div className="content-head mode-label element">
												<h4 className="content-title">
													<FormattedMessage id="place" />
												</h4>
											</div>
											<div className="essential-item-content">
												{article.eventPlace}
											</div>
										</div>
									)}

								{article.articleType === "termin" &&
									article.eventStartEndtime && (
										<div className="essential-item">
											<div className="content-head mode-label element">
												<h4 className="content-title">
													<FormattedMessage id="time" />
												</h4>
											</div>
											<div className="essential-item-content">
												{article.eventStartEndtime}
											</div>
										</div>
									)}

								{article.articleType === "projekt" &&
									article.students && (
										<StudentsEssentials
											students={article.students}
										/>
									)}

								{article.relatedPersons.length > 0 && (
									<RelatedPersonsEssentials
										article={article}
									/>
								)}

								{article.articleType === "lehrangebot" && (
									<CourseEssentials article={article} />
								)}

								{0 < article.relatedFiles.length && (
									<ArticleDownloads
										relatedFiles={article.relatedFiles}
									/>
								)}

								{imagesEssentials.length > 0 && (
									<div className="gallery-wrapper">
										<div className="gallery-inner">
											<MediaEssentials
												medias={imagesEssentials}
											/>
										</div>
									</div>
								)}
							</div>
						</ScrollContent>
					</div>
				</div>
			</ScrollContentMain>

			<RelatedProjects article={article} />
		</AppLayout>
	);
}
