import { useEffect, useState } from "react";
import { Link, useFetcher } from "@remix-run/react";
import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";

import SearchResultsArticle from "./SearchResultsArticle";
import SearchResultsLehrangebot from "./SearchResultsLehrangebot";
import SearchResultsPerson from "./SearchResultsPerson";
import SearchResultsProject from "./SearchResultsProject";
import SearchResultsSectionProject from "./SearchResultsSectionProject";
import SearchResultsSectionArchive from "./SearchResultsSectionArchive";
import SearchResultsEvent from "./SearchResultsEvent";
import SearchResultsDefault from "./SearchResultsDefault";
import SearchResultsPublication from "./SearchResultsPublication";

export type SearchResultsProps = {
	data: typo3.SolrData;
	id?: number;
	showEmptyError?: boolean;
	showLoadMore?: boolean;
	loadMoreUrlTemplate?: (props: {
		loadMoreLink: string;
		id?: number;
	}) => string;
};

type SearchResultsBodyProps = {
	template: typo3.SolrData["template"];
	documents: Array<typo3.SearchResultDocument>;
	showEmptyError?: boolean;
};

function SearchResultsBody({
	template,
	documents,
	showEmptyError,
}: SearchResultsBodyProps) {
	switch (template) {
		case "person":
			return <SearchResultsPerson documents={documents} />;

		case "article":
		case "media":
			return <SearchResultsArticle documents={documents} />;

		case "lehrangebot":
			return <SearchResultsLehrangebot documents={documents} />;

		case "publication":
			return <SearchResultsPublication documents={documents} />;

		case "project":
			return <SearchResultsProject documents={documents} />;

		case "sectionProject":
			return <SearchResultsSectionProject documents={documents} />;

		case "sectionArchive":
			return <SearchResultsSectionArchive documents={documents} />;

		case "event":
			return (
				<SearchResultsEvent
					documents={documents}
					showEmptyError={showEmptyError}
				/>
			);

		default:
			return <SearchResultsDefault documents={documents} />;
	}
}

export default function SearchResults({
	data,
	id,
	showEmptyError = false,
	showLoadMore = false,
	loadMoreUrlTemplate = ({ id, loadMoreLink }) =>
		`/solr-results/${id}${loadMoreLink}`,
}: SearchResultsProps) {
	const [fetcherKey, setFetcherKey] = useState(0);
	const fetcher = useFetcher<typo3.SolrData>({
		key: `section-projects-${fetcherKey}-${id}`,
	});
	const initialResults = data.documents?.list.results;
	const [results, setResults] = useState<
		Array<typo3.SearchResultDocument> | undefined
	>(initialResults);

	useEffect(() => {
		setResults(initialResults);
		setFetcherKey((k) => k + 1);
	}, [initialResults]);

	useEffect(() => {
		if (!fetcher.data || fetcher.state === "loading") {
			return;
		}

		// If we have new data - append it
		const newResults = fetcher.data.documents?.list.results ?? [];
		setResults((prevResults = []) => [...prevResults, ...newResults]);
	}, [fetcher.data, fetcher.state]);

	if (results === undefined) {
		return null;
	}

	const moreDocuments = fetcher.data?.documents;

	const loadMoreLink =
		showLoadMore &&
		((moreDocuments
			? moreDocuments.pagination.next
			: data.documents?.pagination.next) ??
			null);

	return (
		<>
			<SearchResultsBody
				template={data.template}
				documents={results}
				showEmptyError={showEmptyError}
			/>

			{loadMoreLink && (
				<Link
					className="button--load-more"
					to={loadMoreLink}
					onClick={(e) => {
						e.preventDefault();
						fetcher.load(loadMoreUrlTemplate({ id, loadMoreLink }));
					}}
				>
					<FormattedMessage id="loadMore" />
				</Link>
			)}
		</>
	);
}
